export const NavLinks = [
  {
    id: 1,
    Name: "Home",
    route: "",
  },
  {
    id: 2,
    Name: "Services",
    route: "/services",
    children: [
      {
        id: 23,
        Name: "Fit out & Renovations",
        route: "/fit-out-services",
        children: [
          {
            id: 41,
            Name: "Kitchen Renovation",
            route: "/kitchen-renovation",
          },
          {
            id: 42,
            Name: "Bathroom Renovation",
            route: "/bathroom-renovation",
          },
          {
            id: 43,
            Name: "Painting Flooring",
            route: "/painting-flooring",
          },
          {
            id: 44,
            Name: "Wardrobe & Cabinets",
            route: "/wardrobe-&-cabinets",
          },
          {
            id: 45,
            Name: "Villa Renovations",
            route: "/villa-renovations",
          },
          {
            id: 46,
            Name: "Office Renovation",
            route: "/office-renovation",
          },
          {
            id: 47,
            Name: "Villa Extensions & Doors",
            route: "/villa-extensions-&-doors",
          },
          {
            id: 48,
            Name: "Landscaping",
            route: "/landscaping",
          },
          {
            id: 49,
            Name: "Interior Renovation",
            route: "/interior-renovation",
          },
          {
            id: 50,
            Name: "Exterior Revamp",
            route: "/exterior-revamp",
          },
          {
            id: 51,
            Name: "Lightning Installation",
            route: "/lightning-installation",
          },
        ],
      },
      {
        id: 25,
        Name: "RPH Investment Support",
        route: "/investment-support",
      },

      {
        id: 24,
        Name: "Maintenance",
        route: "/maintenance-company",
        children: [
          {
            id: 31,
            Name: "Villa Maintenance",
            route: "/villa-maintenance",
          },
          {
            id: 32,
            Name: "Appartment Maintenance",
            route: "/appartment-maintenance",
          },
          {
            id: 33,
            Name: "Swimming Pool",
            route: "/swimming-pool",
          },
          {
            id: 34,
            Name: "AC Installation",
            route: "/ac-installation",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    Name: "About",
    route: "/about",
  },
  {
    id: 4,
    Name: "Portfolio",
    route: "/portfolio",
  },
  { id: 5, Name: "Blog", route: "/blogs" },
  {
    id: 6,
    Name: "Contact",
    route: "/contact",
  },
  {
    id: 7,
    Name: "FAQ",
    route: "/faq",
  },
];
